<script setup>
import DimensionOption from '@/pages/system/steps/option/dimensionOption.vue'
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'

const store = useStore()

// ref
const heightMax = ref(0)

const step = computed(() => {
  return store.getters['system/currentStep']
})
const dimension = computed(() => {
  const steps = store.getters['system/steps']
  const options = steps.find(s => s.model_step).options
  const option = options.find(o => o.selected)
  const arr = option.special_dim.map(s => {
    if ((s.width.from <= Number.parseInt(step.value.width.value) && s.width.to >= Number.parseInt(step.value.width.value)) && s.price > 0) {
      return Math.max(s.height.to)
    }
  })
  arr.filter(a => {
    if (typeof a !== 'undefined') {
      heightMax.value = a
    }
  })
  return option.dimension
})
</script>
<template>
  <div v-if="dimension" class="step__body">
    <h3>{{ step.name }}</h3>
    <p>{{ step.description }}</p>
    <div class="row option">
      <DimensionOption :option="step.width" :option-dim="dimension.width" type="width"/>
      <DimensionOption :option="step.height" :option-dim="dimension.height" :height-max="heightMax" type="height"/>
    </div>
<!--    <div class="row">-->
<!--      <div class="col-md-12">-->
<!--        <br>-->
<!--        <div class="alert alert-info" v-if="dimensionCapsText">{{ dimensionCapsText }}</div>-->
<!--        <div class="badge text-danger" v-if="name === 'Schiebetore'">Ausleger: {{ ausleger }}cm-->
<!--        </div><tooltip :tooltip="tooltip" style="display:inline-block" v-if="name === 'Schiebetore'"><span class="fa fa-info-circle"></span></tooltip>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
